import React, { useContext } from "react";
import {Layout, MetaTags}  from '../components'
import { Link } from "gatsby";
import styles from "./favorite.module.css";
import {saveFavChartsInStorage} from "../utils";
import FavoriteContext from "../favoriteContext";
import ChartIcon from "../components/ChartIcon";
import { getPostsFromGraphQL } from "../utils";

const List = ({data}) => {
  const thePosts = getPostsFromGraphQL(data, true);
  const convertToEntry = ({title, slug, variations}) => [slug, {title, isVariation: (variations || variations === 0) ? false : true}];
  const posts = Object.fromEntries(thePosts.map( convertToEntry ) );

  return (
    <Layout type="full">
      <MetaTags
        title={`My Favorite Charts - Graphopedia`}
      />
      <h1> Favorite Charts </h1>
      <FavoritesList allPosts={posts} />
    </Layout>
  );
};

const FavoritesList = ({allPosts}) => {
  const {charts, setCharts} = useContext(FavoriteContext);

  const theCharts = Object.keys(charts);
  const items = theCharts.reverse(); // display charts in the reverse order

  const deleteChart = (chartId) => {
    const theChart = charts[chartId];
    if (theChart) {
      const newCharts = Object.assign({}, charts); // clone
      delete newCharts[chartId];
      saveFavChartsInStorage(newCharts); // update local storage
      setCharts(newCharts) // update favorite charts context
    }
  }

  if (items.length === 0) {
    return (
      <div className={styles.emptyList}>
        <div>You haven't favorited any charts.</div>
        <div>Back to <Link to={"/"}> chart list</Link></div>
      </div>
    )
  }

  return (
    <ul className={styles.chartList}>
    {items.map(function (chart, i){
      const chartTitle = allPosts[chart]?.title;
      const isVariation = allPosts[chart]?.isVariation;
      return (
          <li key={i}>
              <Link to={"/" + chart} key={i}>
                <span className={styles.iconWrapper}>
                  <ChartIcon slug={chart} showStack={false} isVariation={isVariation} />
                </span>
                <strong className={styles.itemDescription}>{chartTitle}</strong>
              </Link>
              <DeleteButton chartId={chart} deleteChart={deleteChart} />
          </li>
      )
    })}
    </ul>
  ) 
}

const DeleteButton = ({chartId, deleteChart}) => {

  const handleClick = () => {
    deleteChart(chartId);
  }

  return (
    <span className={styles.deleteBtn} onClick={handleClick}>×</span>
  )
}

export default List;

export const postsQuery3 = graphql`
  query PostsQuery3 {
    ...postList
  }
`;